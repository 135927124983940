import React from 'react';
import { Box } from '@mui/system';
import { Tabs, Tab, Button } from '@mui/material';
import Forms from '../Forms/Forms';
import { useParams, useNavigate } from 'react-router-dom';
import Profile from './Profile';
import Gallery from './Gallery';
import { useBulletins } from '../../services';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const pages = ['bulletins', 'photo-gallery', 'forms'];

const Bulletins = () => {
  const { bulletins } = useBulletins();

  return (
    <ul>
      {bulletins.map((b) => (
        <li key={b.id} style={{ padding: '0.5rem' }}>
          <Button
            variant="outlined"
            href={b.link}
            target="_blank"
            color="primary"
          >
            View {b.month} {b.year} Bulletin
          </Button>
        </li>
      ))}
    </ul>
  );
};
const Members = () => {
  const { page } = useParams();
  const nav = useNavigate();

  const value = page ? pages.indexOf(page) + 1 : 0;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // setValue(newValue);
    nav(`/members/${newValue === 0 ? '' : pages[newValue - 1]}`);
  };
  return (
    <Box component={'span'}>
      <div className="contactus-hero">Members</div>
      <Box sx={{ width: '100%' }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
            indicatorColor="secondary"
            scrollButtons="auto"
            // variant="scrollable"
          >
            <Tab label="Profile" {...a11yProps(0)} />
            <Tab label="Bulletins" {...a11yProps(1)} />

            <Tab label="Photos" {...a11yProps(2)} />
            <Tab label="Forms" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Profile />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Bulletins />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Gallery />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Forms />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Members;
