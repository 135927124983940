import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@mui/system';
import {
  Button,
  Container,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useBulletins } from '../../services';
import { User } from '../../types';
import { months } from '../../utils';

const years = Array.from({ length: 7 }, (_, i) => 2024 + i);

const Bulletins = ({ user }: { user: User }) => {
  const [newBulletin, setNewBulletin] = useState({
    file: null as File | null,
    month: '',
    year: '',
  });
  const [fileName, setFileName] = useState<string | null>(null);
  const [saving, setSaving] = useState(false);
  const [open, setOpen] = useState(false);
  const { bulletins, createBulletin, deleteBulletin } = useBulletins();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [bulletinToDelete, setBulletinToDelete] = useState<string | null>(null);

  useEffect(() => {
    const nextMonth = new Date();
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    setNewBulletin((prev) => ({
      ...prev,
      month: months[nextMonth.getMonth()],
      year: nextMonth.getFullYear().toString(),
    }));
  }, []);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setNewBulletin({
      ...newBulletin,
      file,
    });
    setFileName(file ? file.name : null);
  };

  const handleSubmit = async () => {
    setSaving(true);
    await createBulletin(newBulletin);
    setSaving(false);
    setOpen(true);
    setNewBulletin({
      file: null,
      month: '',
      year: '',
    });
    setFileName(null);
  };

  const handleDeleteClick = (id: string) => {
    setBulletinToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (bulletinToDelete) {
      await deleteBulletin(bulletinToDelete);
      setDeleteDialogOpen(false);
      setBulletinToDelete(null);
    }
  };

  if (!user?.isAdmin) return null;

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <h2>Create a new Bulletin</h2>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <FormControl sx={{ minWidth: 120, marginBottom: '1rem' }}>
            <InputLabel>Month</InputLabel>
            <Select
              label="Month"
              value={newBulletin.month}
              onChange={(e) =>
                setNewBulletin({ ...newBulletin, month: e.target.value })
              }
            >
              {months.map((month) => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 120, marginBottom: '1rem' }}>
            <InputLabel>Year</InputLabel>
            <Select
              label="Year"
              value={newBulletin.year}
              onChange={(e) =>
                setNewBulletin({ ...newBulletin, year: e.target.value })
              }
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <input
          type="file"
          accept="application/pdf"
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => fileInputRef.current?.click()}
          style={{ marginBottom: '1rem' }}
          startIcon={<AttachFileIcon />}
        >
          Attach PDF
        </Button>
        {fileName && (
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            Selected file: {fileName}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          startIcon={saving ? <CircularProgress size={20} /> : null}
          disabled={
            saving ||
            !newBulletin.month ||
            !newBulletin.year ||
            !newBulletin.file
          }
          onClick={handleSubmit}
        >
          {saving ? 'Saving...' : 'Save'}
        </Button>
        <List sx={{ width: '80%', mt: 5 }}>
          {bulletins.map((bulletin) => (
            <ListItem key={bulletin.id}>
              <ListItemText
                primary={`${bulletin.month} ${bulletin.year}`}
                secondary={
                  <a href={bulletin.link} target="_blank" rel="noreferrer">
                    View Bulletin
                  </a>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteClick(bulletin.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this bulletin?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setOpen(false)}
            severity="success"
            sx={{ width: '100%' }}
          >
            Bulletin Added Successfully
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default Bulletins;
