import { db } from './App';
import {
  collection,
  addDoc,
  getDoc,
  doc,
  onSnapshot,
  query,
  where,
  setDoc,
  limit,
  orderBy,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { EmploymentFormState } from './types';
import { IBudgetFormData } from './components/Forms/BudgetRequestForm';
import { IBreastFormData } from './components/Forms/BreastCancer';
import { useEffect, useState } from 'react';
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
  uploadBytesResumable,
} from 'firebase/storage';

export const FormIds = {
  contact: 'contact',
  volunteer: 'volunteer',
  schoolBash2025: 'school_bash_2023',
  budgetForm2024: 'budget-request-form-2024',
  budgetForm2025: 'budget-request-form-2025',
  breastCancerSeminar2024: 'breast-cancer-seminar-form-2024',
  mentalHealth2024: 'mental-health-symposium-2024',
  skateworldSpringBreak: 'skate_night',
  bolden2024: 'bolden_2024',
  schoolBash2024: 'school_bash_2024',
  requisitionForm: 'requisition-form',
  youthChristmasPart2024: 'youth-christmas-party-2024',
};
export interface Ministry {
  id: string;
  name: string;
  description: string;
  media: string[];
  content?: string;
}

export interface Announcement {
  id: string;
  text: string;
  link?: string;
  callToAction?: string;
  createdOn: Date;
  displayUntil: Date;
  creatorId: string;
  enabled: boolean;
}

export interface ChurchEvent {
  id: string;
  title: string;
  description: string;
  startTime: Date;
  endTime: Date;
  media: string[];
  creator: string;
  signUpFormId?: string;
  link: string;
  externalLink?: string;
}

export interface VolunteerInput {
  lastName: string;
  firstName: string;
  middleName: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  homePhone: string;
  cellPhone: string;
  email: string;
  ministry: string;
  acceptedJesus: boolean;
  explanation: string;
  churchHistory: string;
  completedClass: boolean;
  volunteerExperience: string;
  backgroundCheck: boolean;
  employment?: EmploymentFormState | null;
  signature?: string;
  signatureDate?: string;
}

export const churchMinistries: Ministry[] = [
  {
    id: '1',
    description: 'Making sure God’s message is seen and heard.',
    media: ['av.png'],
    name: 'Audio Visual Ministry',
    content:
      '<p>The Audio-Visual Ministry Team meets the overall audio-visual needs of New Mt. Zion Missionary Baptist Church and is responsible for providing a distraction-free audio-visual worship experience. This team develops, supports, and enhances the technical aspects of the worship services and is responsible for the quality of the audio generated during each church service. The audio-visual team is accountable for soundboard management and equipment and ensures that event live streaming and video management production is performed with excellence.</p><p>This team is comprised of interdisciplinary cyber evangelists who possess expertise in the mediums of photography, video, audio, social media, online streaming, and other technologies. In addition, the ministry team facilitates and enhances the church’s praise, worship, teaching, and healing services. The team supports other ministries and interacts with members and guests to spread the gospel and Godly love. Whether the team is supporting events and services for the Health and Wellness Ministry, the Youth Ministry, funeral services, and everything in between- members and guests will see the audio-visual ministry there dutifully laboring alongside to honor the Lord God Almighty.</p>',
  },
  {
    id: '2',
    description:
      'Nourishing your body (with delicious meals) and your soul through Christ.',
    media: ['culinary.jpg'],
    name: 'Culinary Ministry',
    content:
      '<p>The culinary Ministry of New Mt. Zion plans and coordinates all meal services within the church in a loving and caring manner. This ministry supports and enhances food preparations for all special events and programs by preparing and serving healthy and delicious food. The ministry believes these events provide an excellent opportunity for fellowship among our church family and their guests.</p><p>The Culinary Ministry desires that everyone who experiences the food they prepare knows that the Love of God is the main ingredient. This ministry supports the pastor and encourages comfort and joy among the members</p>',
  },
  {
    id: '3',
    description:
      'Mary started telling the Good News at the opened tomb, and Women must tell the Gospel Message as Jesus commanded',
    media: ['closet.png'],
    name: 'Clothes Closet Ministry',
    content: `<div class="elementor-widget-container">
							<p>Mary started telling the Good News at the opened tomb, and Women must tell the Gospel Message as Jesus commanded. New Mt. Zion Missionary Baptist Church Senior Women are engaged in weekly Missionary work in the form of a “<b>Clothes Closet</b>”. This is a vital arm of the Church Which benefits Men, Women, and Children within our community.&nbsp;</p><p><b>When are we open?</b><span style="color: #bf0a30;"><b><br></b></span><span style="color: var( --e-global-color-secondary ); font-family: var( --e-global-typography-text-font-family ), Sans-serif; font-weight: var( --e-global-typography-text-font-weight );">We are open on Monday and Thursday from 10:00 AM To 1:00 PM.&nbsp;</span></p><p><b>What can you donate?<br></b>We accept donations of clean and usable clothing and small household items</p><p><span style="color: var( --e-global-color-secondary ); font-family: var( --e-global-typography-text-font-family ), Sans-serif; font-weight: var( --e-global-typography-text-font-weight );">Today, we have over 22 committed weekly volunteers and this year have served in excess Of 100 customers in the community. We warmly welcome members of our Church who are ready and willing to serve. The harvest is truly plenteous, but the laborers are few.</span></p>						</div>`,
  },
  {
    id: '4',
    description:
      'Ladies First: We empower and support our women in leadership.',
    media: ['deaconess.jpg'],
    name: 'Deaconess Ministry',
    content: `In the same way, their wives are to be women worthy of respect, not malicious talkers, but temperate and trustworthy in everything. – 1 Timothy 3:11 NIV The Deaconess Ministry of New Mt Zion Missionary Baptist Church are women who are committed to the Gospel of JESUS CHRIST through the works of spiritual care and the teaching of spiritual faith.  With a Servant’s heart, we prepare the Communion each first Sunday in the month and assist those candidates who are ready for Baptism. In addition, we also reach out to the Sick & Shut In, the bereaved and elderly members, and the Women of the church. We are spiritual caregivers within the body of CHRIST and throughout the community.  The Deaconess Ministry currently consists of sixteen women who are always ready to serve appropriately. The women set apart for this ministry are women of sound judgment, benevolent in spirit, tolerant of the weakness of others, sympathetic, and willing to serve to the best of their knowledge and ability. Our continued commitment is to love and serve`,
  },
  {
    id: '5',
    description:
      'Brotherhood: Strong men supporting our pastor through Christ.',
    media: ['deacons.jpg'],
    name: 'Deacons Ministry',
    content: `The Deacon’s ministry of NMZ consists of saved, spiritual, set-apart men who lead by serving the church body. The goal of their service is to assist the Pastor so that he may continually prioritize the word and prayer. The biblical basis and qualifications for this ministry are found in Acts 6:1-6 and 1 Timothy 3:8-13.`,
  },
  {
    id: '6',
    description: 'Dedicated to making our church home more beautiful than ever',
    media: ['decor.jpg'],
    name: 'Decor Ministry',
    content: `<div class="elementor-widget-container">
			<style>/*! elementor - v3.14.0 - 18-06-2023 */
.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}</style>				The Décor Ministry volunteers use their talents and gifts to create and enhance a beautiful, welcoming, and worshipful environment that reflects Christian love, glorifies God and reflects His beauty. The Décor Ministry coordinates flowers, greenery, pictures, and furniture to ensure a fresh, inviting, and cheerful atmosphere in the sanctuary, foyer, classrooms, fellowship hall, bathrooms, and other areas as designated. The ministry provides leadership and coordinates the type and timing of seasonal décor transitions.</p><p>&nbsp;The ministry supports and coordinates the décor for corporate-wide church events and the various events sponsored by the church’s ministries. Corporate and seasonal décor is funded through the Décor Ministry budget, and other ministry events are supported as designated in their ministry budget. The ministry collaborates with the Trustee Ministry’s building and ground committee in selecting the décor for various church facilities and remodeling projects.</p><h6>EXAMPLES OF CORPORATE-WIDE CHURCH EVENTS INCLUDE:</h6><ul><li>Christmas</li><li>Resurrection Sunday</li><li>Church Anniversary and related events</li><li>Pastors Anniversary and related events</li><li>Ministry Events</li></ul>						</div>`,
  },
  {
    id: '7',
    name: 'Evangelism Ministry',
    description: 'Come see our unwavering commitment to spreading the Word.',
    media: ['evangelism.jpg'],
    content: `<p>Therefore make disciples of all nations, baptizing them in the name of the Father and of the Son and of the Holy Spirit, 20 and teaching them to obey everything I have commanded you. And surely, I am with you always, to the very end of the age.” Mathew 28:19-20 NIV </p>

The Evangelism Ministry are followers of Christ who are called to share His message of hope, love, and salvation. The team works passionately and fervently to draw the lost into the Body of Christ through prayer and public witnessing that leads others to a personal relationship with Christ. This ministry obeys the command to make disciples of all nations and communities. This ministry shares the gospel message and teachings of Jesus Christ and shares His promise of eternal life.
The evangelism team answers the call to reach and touch the lives of people who may not know that God loves them and that He has a plan for them. The ministry adheres to the commission to proclaim the gospel and salvation so that those who are lost can be found and those who are saved can be transformed to do more wondrous works.`,
  },
  {
    id: '8',
    name: 'Health & Wellness Ministry',
    description: 'Prioritizing health in our communities.',
    media: ['health.jpg'],
    content: `<h2 class="elementor-heading-title elementor-size-default"> Our vision: Transforming Lives and Creating a Culture of Wellness</h2>
    <p>9&nbsp;Do you not know that your bodies are temples&nbsp;of the Holy Spirit, who is in you, whom you have received from God? You are not your own;&nbsp;20&nbsp;you were bought at a price.&nbsp;Therefore, honor God with your bodies. 1 Corinthians 6: 10-20 NIV</p>
    <h2 class="elementor-heading-title elementor-size-default">WHO WE ARE</h2>
    <p>The New Mt. Zion Lakeland Health and Wellness Ministry was established to meet our church and community’s growing chronic health challenges at the grassroots level. The ministry comprises Christian believers from health-related professions, including, Registered Nurses, nurse practitioners, educators, public health leaders, and support staff. A primary goal is to develop educational programs to move our faith from health awareness to health engagement. The ministry’s core values and attributes are based on Biblical guidance. The founding principles include Faith and Prayer, Education and Empowerment, Advocacy and Support, and Health and well-being.</p>
    <h2 class="elementor-heading-title elementor-size-default">WHAT WE DO</h2>
    <ul><li>Provide educational literature and workshops on chronic health conditions and risks such as Diabetes, Stroke, Hypertension, and Cancer.</li><li>Conduct a breast cancer awareness program annually in October.</li><li>Provide CPR, AED, and First-Aid courses for ministry members.</li><li>Monitor the COVID-19 protocols and make other recommendations as needed.</li><li>Provide seasonal updates on the Flu and other respiratory illnesses.</li><li>Promote health awareness and advocacy among the membership and community.</li><li>Provide first-aid for members who become ill during a worship service.</li><li>Partner with public health departments and local hospitals to improve the health of our community, including providing COVID vaccines, boosters, and flu vaccines.</li></ul>`,
  },
  {
    id: '9',
    name: 'Hospitality Ministry',
    media: ['hosp.jpg'],
    description: 'You’re invited! Making our guests feel right at home',
    content: `<p><strong>Love each other deeply because love covers a multitude of sins. Offer hospitality to one another<br>without grumbling. 1 Peter 4:8-9</strong><br>The Hospitality Ministry helps guests feel and experience the love of God by welcoming them into God’s house through friendly and authentically gracious service. They are the front line of assisting visitors entering and exiting the<br>church.<br><strong>This ministry also:</strong></p>
    <ul><li>Pass out church visitor contact information and literature and provide information to members and visitors concerning church services and upcoming events.</li><li>Have teams in the lobby to answer questions and direct members into the<br>sanctuary and other areas of the church.</li></ul>`,
  },
  {
    id: '10',
    name: 'MINISTERS Of The WORD MINISTRY',
    description:
      'Preach the Word, be ready in season and out of season! 2 Timothy 4:2',
    media: ['ministers.jpg'],
    content: `The Ministers of the Word Ministry of New Mt. Zion consist of saved and spiritual men who are students and sharers of the Word of God. God called and commissioned them to preach and teach his word publicly to the gathered church body. The character and conduct of this ministry are guided and guarded by the words of 1 Timothy 3:1-7. The prioritizing principle of this ministry is to give themselves to the Word of God and Prayer wholeheartedly.

`,
  },
  {
    id: '11',
    name: 'Ministers’ Wives Ministry',
    description: 'Ministers’ Wives Ministry',
    media: ['ministers-wives.jpg'],
    content: `The New Mt. Zion Ministers’ Wives Ministry uniquely serve the Lord as they serve alongside and support their husbands; They steadfastly support God’s vision for the church. Minister’s wives experience situations unique to this special call. They share the joys and insights with others who serve as ministers’ wives. Ministers’ wives support their husband’s ministry and pray for each other.`,
  },
  {
    id: '12',
    name: 'Musicians And Directors Ministry',
    description:
      'The New Mt. Zion Musicians and Directors provide oversight of the choirs, praise and worship teams, and musicians.',
    media: ['music.jpg'],
    content: `<h2 class="elementor-heading-title elementor-size-default">Let the message of Christ dwell among you richly as you teach and admonish one another with all wisdom through psalms, hymns, and songs from the Spirit, singing to God with gratitude in your hearts.                           Colossians 3:16</h2>
    <div class="elementor-widget-container">
			<style>/*! elementor - v3.14.0 - 18-06-2023 */
.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}</style>				The New Mt. Zion Musicians and Directors provide oversight of the choirs, praise and worship teams, and musicians.  The pastor appoints the leaders of this ministry to direct music throughout the church’s life. These leaders work collaboratively to assist the pastor in planning, conducting, and evaluating a comprehensive music ministry.  This also includes using the congregation’s musical gifts to aid praise and worship by leading the congregation to minister to each other through music.  These musical leaders share an appreciation of music and foster musical talents and expression among all age groups within the church. 						</div>
<div class="elementor-widget-container">
							The Music Ministers Ministry highlights the spiritual dimension of music as a means of spiritual growth and development. 						</div>
    `,
  },
  {
    id: '13',
    name: 'History And Heritage Ministry',
    description:
      'The History and Heritage Ministry documents the historical journey of New Mt. Zion with orderly records',
    media: ['heritage.jpg'],
    content: `<p>The History and Heritage Committee documents the historical journey of New Mt. Zion with orderly records so that our generational heritage, discipleship, and special events are documented and preserved. We seek to honor our past, rejoice in the present, and prayerfully shape our future through our recorded activities.</p>
    <p><b>This ministry’s role includes:</b></p>
    <p>
    <ul>
    <li>Locating and preserving all the church’s historical records, photographs, and other related materials</li>
    <li>Assist the church in making and keeping accurate, comprehensive records of its current life and work.
    </li>
    <li>Assist the church with special occasions such as the church anniversary.</li>
    <li>Communicate our heritage and the generation grace of the membership.</li>
    </ul>
    </p>
`,
  },
  {
    id: '14',
    name: 'Music Ministry',
    description: 'Glorying Christ: We Dance, We Sing, We Praise.',
    media: ['music2.png'],
    content: `<p>In Hebrew worship and throughout church history, choirs have been an important factor in group worship and the proclamation of the gospel. The choir is vitally
important to the work of the church. The purpose is to glorify Jesus Christ by allowing Him to use the choir as a tool by working through each other</p>
    <b>Choir members are:</b><br/>
    Essential in preparing the hearts of the congregation for the preaching of the Word of God.
Charged to bless the members of the congregation and members of the choir alike through leading the congregation in the singing of songs and hymns.
To be an example to the other members of the church by the testimony of their
lives. Every member must be a born again believer.
Expected to be committed and faithful and be in attendance regularly for
rehearsals
    <p></p>`,
  },
  {
    id: '15',
    name: 'Nursing Home Ministry',
    description: 'Taking joy and fellowship to local nursing homes.',
    media: ['nursing.jpg'],
    content:
      'The goal of the Nursing Home Ministry is to glorify God by fellowshipping with the residents and staff of adult care facilities to share the love of our Lord, the Word of God, and the offer of salvation through Jesus Christ our Lord. Our caring team of volunteers experience great joy as they share God’s Word, sing songs of praise, and offer words of encouragement, hope, and prayer to the elderly who are living in the twilight years of their life. Our ministry team finds the time to talk, read, and sing to the residents while developing friendships through time spent ministering and sharing with them.',
  },
  {
    id: '16',
    name: 'New Member Ministry',
    media: ['newmember.jpg'],
    description: 'Arms wide open: Welcoming new members the right way.',
    content: `<p><i>" So, in Christ, we, though many, form one body, and each member belongs to all the others. " Romans 12:5 NIV</i></p>
    <p>With the guidance of the Holy Spirit, the New Member Ministry’s purpose is to welcome and connect newly joined members into a joyful fellowship with our Savior, Jesus Christ, and the New Mt. Zion’s church family. We are a Bible-based church focusing on relevant teaching, heart-felt worship, honest friendships, constant prayer, and compassionate care for those in need. Our goal is to create an environment of contagious Christianity that influences and encourages the members.</p>
    <p><b>New Member Classes</b></p>
    <p>The new member orientation classes provide the essential introduction to our Church’s Mission, Ordinances, and, most importantly, our Commitments to Christ and His Church. All new members must complete four consecutive weekly classes starting on the first Sunday of each month beginning at 9:30 AM. If a class is missed, the instructor provides assistance with scheduling a make-up class. Upon completing all new member classes, the member receives a certificate of completion at a scheduled church service.</p>`,
  },
  {
    id: '17',
    name: 'Office Staff Ministry',
    media: ['office.jpg'],
    description:
      'The Administrative staff of NMZ are trusted gatekeepers who bear humility, a fruit of the Holy Spirit',
    content: `<p><i>“Galatians 5:22-23 “But the fruit of the Spirit is love, joy, peace, patience, kindness, goodness, faithfulness, gentleness and self-control.” The fruit of the Holy Spirit is the result of the Holy Spirit’s presence in the life of a Christian.
</i></p><p>he Administrative staff of NMZ are trusted gatekeepers who bear humility, a fruit of the Holy Spirit and as such reflects the nature of God in his/her behavior. The staff meet and greet parishioners and visitors electronically, by phone, and in person. The Administrative staff ensures the church is well-run and maintained by managing its daily operations through supervising staff and volunteers, keeping parishioners informed, and managing the church’s calendar and events. The staff ensures the effective use of church income by keeping accurate records and work with the community and supports the pastor and other church staff members.
</p>`,
  },
  {
    id: '18',
    name: 'Parking Lot Ministry',
    description: 'Directing traffic and making your day easier.',
    media: ['parking.jpg'],
    content: `<p>The New Mt. Zion Parking Lot Ministry’s purpose is to assist with traffic flow and vehicle parking for members and guest attending a church service. Our goal is to facilitate safety and convenience.   The parking lot ministry team members are the initial point of contact and serve as the first greeters.</p>
    <p>All parking lots are clearly labeled and are routinely patrolled and monitored using golf carts and other technology.  The ministry members assist with transporting members in need to the entrance of the church building.  Each golf cart is equipped with umbrellas.</p>`,
  },
  {
    id: '19',
    name: 'Prison Ministry',
    description: 'Prayer changes things (and people).',
    media: ['prison.jpg'],
    content: `<p><i>“………………. I was in prison, and you came to visit me.” Matthew 25:36 NIV </i></p><p>The Prison Ministry believes that every person is made in the image of God and that no life is beyond His reach. This ministry serves those men, women, and children affected by crime and incarceration and works to see lives restored in and out of prison through fellowship and sharing God’s plan for salvation. This ministry visits jails and prisons to evangelize, provide hope, and proclaim the word of God and His saving power to inmates and others, letting them know that God can change their lives and remove the guilt and shame from their past behaviors.</p>`,
  },
  {
    id: '20',
    name: 'Senior Women’s Outreach Ministry',
    description:
      'Providing prayer, comfort, and support for the sick and shut in.',
    media: ['senior_women.jpg'],
    content: `<p><i>And we know that all things work together for good to them that love God, To them that are called according to His purpose. (Romans 8:28)</i></p>
    <p>The Senior Women’s Outreach Ministry is about service and making a difference in the lives of others. We strive daily to let others see Christ in us. The Ministry theme is “LOVE IN ACTION.”</p><b>The primary focus of our ministry is to:</b>
<ol>
<li>Support our sick members by calling, encouraging, and providing whatever is needed.</li>
<li>Support bereaved families with food, phone calls, and love offerings.
</li>
<li>Provide quarterly Outreach programs with Talbot House, where we provide lunch and witness. We also provide clean, empty medicine bottles to Talbot, which they use to Distribute lotion and shampoo to the homeless.
</li>
<li>Provide transportation for senior women when needed.
</li>
<li>Send get-well, sympathy, and birthday cards to our Senior Women.
</li>
<li>Provide a Clothes Closet on Mondays and Thursdays to give Clothing for the needy.
</li>
<li>Conduct an annual Shar-a-thon in November to provide food and clothing for those in need.
</li>
<ol>
<p>
<b>OUR MISSION SONG: THIS IS MY MISSION</b>
</p>
<p>This is my mission; this is my Prayer<br>Helping the Needy everywhere.<br>Thinking of others, Willing to share<br>Taking God’s Message everywhere!</p>
`,
  },
  {
    id: '21',
    name: 'Security Ministry',
    description: 'Keeping you safe as you worship in Christ.',
    media: ['security.png'],
    content: `<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-873aaf3 elementor-widget elementor-widget-heading" data-id="873aaf3" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<style>/*! elementor - v3.14.0 - 18-06-2023 */
.elementor-heading-title{padding:0;margin:0;line-height:1}.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a{color:inherit;font-size:inherit;line-height:inherit}.elementor-widget-heading .elementor-heading-title.elementor-size-small{font-size:15px}.elementor-widget-heading .elementor-heading-title.elementor-size-medium{font-size:19px}.elementor-widget-heading .elementor-heading-title.elementor-size-large{font-size:29px}.elementor-widget-heading .elementor-heading-title.elementor-size-xl{font-size:39px}.elementor-widget-heading .elementor-heading-title.elementor-size-xxl{font-size:59px}</style>
			<h2 class="elementor-heading-title elementor-size-default">"Maintaining Our Mission While Worshiping in a mindset of Awareness."</h2>		</div>
				</div>
				<div class="elementor-element elementor-element-ab3dcb3 elementor-widget elementor-widget-text-editor" data-id="ab3dcb3" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
			<style>/*! elementor - v3.14.0 - 18-06-2023 */
.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}</style>				<p><strong>Put on the full armor of God so that you can take your stand against the devil’s schemes.</strong><br><em><strong>Ephesians 6:11 NIV</strong></em></p>						</div>
				</div>
				<div class="elementor-element elementor-element-0f44182 list-type-styles elementor-widget elementor-widget-text-editor" data-id="0f44182" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
							<p>New Mt. Zion Missionary Baptist Church Lakeland’s foremost goal is to provide a safe and secure church where members and visitors can worship comfortably with the assurance that all reasonable measures are being taken to ensure their safety.</p><p>A comprehensive corporate-wide safety and security program includes the church’s response to threatening conditions or situations. These situations may present themselves in many ways, including weather conditions, physical violence, property thefts, disruptive individuals, and crimes against children.</p><p>The church’s guidelines are restrictive in some areas but are designed to increase awareness, prevent problems, and take action to keep us safe. Our goal is to be mindful instead of fearful.</p><p><strong>NMZ SAFETY AND SECURITY PROGRAM POLICY COMPONENTS INCLUDE:</strong></p><ul><li>Safety and Security</li><li>Child Protection</li><li>Financial Protection</li><li>Facility Protection</li><li>Transportation</li><li>Background Checks</li><li>Emergency Support</li><li>Ongoing Environmental Safety Checks</li></ul><h5 style="color: #bf0a30;">THE SAFETY AND SECURITY TEAM</h5><ul><li>The NMZ safety and security team is comprised of church members who volunteer to serve in this capacity. All security team volunteers are active members in good standing and are at least twenty-one years old. Every volunteer must complete an application and background check.</li><li>The security team members must complete all required initial and progressive training to standardize knowledge and skills.</li></ul>						</div>
				</div>
					</div>`,
  },
  {
    id: '22',
    name: 'Sunday School Ministry',
    description:
      'The Sunday School Ministry provides Christian education and spiritual formation for children and adults.',
    media: ['sundayschool.jpg'],
    content: `<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-cc533fc elementor-widget elementor-widget-heading" data-id="cc533fc" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<style>/*! elementor - v3.14.0 - 18-06-2023 */
.elementor-heading-title{padding:0;margin:0;line-height:1}.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a{color:inherit;font-size:inherit;line-height:inherit}.elementor-widget-heading .elementor-heading-title.elementor-size-small{font-size:15px}.elementor-widget-heading .elementor-heading-title.elementor-size-medium{font-size:19px}.elementor-widget-heading .elementor-heading-title.elementor-size-large{font-size:29px}.elementor-widget-heading .elementor-heading-title.elementor-size-xl{font-size:39px}.elementor-widget-heading .elementor-heading-title.elementor-size-xxl{font-size:59px}</style><h2 class="elementor-heading-title elementor-size-default">“For whatever was written in the former days was written for our instruction, that through endurance and through the encouragement of the Scriptures, we might have hope.                            Romans 15:4</h2>		</div>
				</div>
				<div class="elementor-element elementor-element-c84f0a3 elementor-widget elementor-widget-text-editor" data-id="c84f0a3" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
			<style>/*! elementor - v3.14.0 - 18-06-2023 */
.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}</style>				<p>The Sunday School Ministry provides Christian education and spiritual formation for children and adults. The purpose of Sunday School is to teach the Bible and to help Christians grow in their knowledge, faith and understanding of God’s Word.</p><p>The Sunday School lessons cover many topics, including Bible stories, Christian doctrine, and the practical application of Biblical principles to daily life. Sunday School provides an opportunity to connect and build relationships with others.</p><p><br>Adult Sunday School classes are held in the main sanctuary from 9:45 AM to 10:45 AM.</p>						</div>
				</div>
				<div class="elementor-element elementor-element-cdda4ea elementor-widget elementor-widget-heading" data-id="cdda4ea" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">CHILDREN’S SUNDAY SCHOOL</h2><br>		</div>
				</div>
				<div class="elementor-element elementor-element-8ff3706 elementor-widget elementor-widget-heading" data-id="8ff3706" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">“Train up a child in the way he should go, and when he is old, he will not depart from it.  			Proverbs 22:6</h2>		</div>
				</div>
				<div class="elementor-element elementor-element-c6f3e8d elementor-widget elementor-widget-text-editor" data-id="c6f3e8d" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
							<p>Children’s Sunday School age groups are five to eight years of age and nine to twelve years of age. &nbsp;Children’s Sunday School is held in the classrooms from 9:45 AM to 10:45 AM.</p>						</div>
				</div>
					</div>`,
  },
  {
    id: '23',
    name: 'Shepherds Care Ministry',
    description: 'Providing care and support for our pastor and his family.',
    media: ['shepherds.jpg'],
    content: `<div class="elementor-widget-container">
			<style>/*! elementor - v3.14.0 - 18-06-2023 */
.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}</style>				<p>Let the elders who rule well be counted worthy of double honor, especially those who labor in the word and doctrine. 1 Timothy 5:17 NKJV</strong></p><p>The Shepherds Care Ministry is designed to fulfill the Biblical responsibilities of providing for the Pastor’s needs. A primary goal is to provide encouragement and expressions of support for the Pastor and his family as he carries out the church’s vision.</p><p>This Ministry assists the Pastor and his family spiritually and financially. The Shepherd’s Care Ministry provides leadership in organizing and promoting church activities that ensure that the first family is remembered with honor, love, appreciation, and monetary gifts on birthdays, pastoral and personal anniversaries, holidays, and other special occasions.</p><p>The Shepherds care ministry prays for guidance, blessings, and perfect harmony for our first family.</p>						</div>`,
  },
  {
    id: '24',
    name: 'Trustee Ministry',
    description: 'A team committed to the best interest of the church.',
    media: ['trustee.jpg'],
    content: `<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fca3e98" data-id="fca3e98" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-c84f0a3 elementor-widget elementor-widget-text-editor" data-id="c84f0a3" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
			<style>/*! elementor - v3.14.0 - 18-06-2023 */
.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}</style>				<p>The New Mt. Zion Missionary Baptist Church’s Trustee Ministry is called to a solemn sense of responsibility. This ministry represents and acts in the overall best interest of the church and ensures that effective and efficient operations are developed, evaluated, and maintained. <br>The Trustee Ministry serves as the church’s asset management and financial accountability overseers and acts as faithful stewards of all the resources of God’s Church. They provide oversight and control of all the church’s buildings and grounds and are responsible for policies and procedures that govern the use of church buildings and equipment. The Trustee Ministry is responsible for the church’s financial operations, including budget development, accounts management, counting and deposits, asset management, and maintenance of financial records</p>						</div>
				</div>
				<div class="elementor-element elementor-element-a05f632 elementor-widget elementor-widget-text-editor" data-id="a05f632" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
							<p>The Trustees preserve prayerful and effective leadership in all matters. The code of ethics covenant ensures excellent working relationships, support for each other, the church’s vision, and Pastoral leadership. The ministry pledges to pray for each other and remain vigilant for matters related to assigned duties. </p><p><strong>CHURCH ACCOUNTANT</strong><br>New Mt. Zion Lakeland maintains a contractual relationship with the church accountant to assist the Trustee Ministry in ensuring the integrity of the church’s financial operations. The accountant audits the church’s financial records, provides an annual certification of its finances, and provides advice and counsel on general accounting principles and other accounting matters, including segregation of duties.</p>						</div>
				</div>
					</div>
		</div>`,
  },
  {
    id: '25',
    name: 'Ushers Ministry',
    description: 'The first impression is always a lasting one.',
    media: ['ushers.jpg'],
    content: `<p>“Better is one day in your courts than a thousand elsewhere; I would rather be a doorkeeper in the house of my God than dwell in the tents of the wicked.” – Psalm 84:10 NIV The Ushers Ministry serves as the doorkeepers and spiritual ambassadors who warmly greet all who enter, showing the love of Jesus Christ through their actions. The usher’s ministry serves as visible representatives of Christ who welcome, seat, and help members and visitors feel comfortable. The usher’s ministry keeps order at the entrance to the sanctuary and distributes copies of bulletins and information on church programs and events. This ministry also assists those in need and notifies the Health and Wellness Ministry of members who become ill during a worship service and are alert to other situations that may occur.</p>`,
  },
  {
    id: '26',
    name: 'Van Ministry',
    description: 'Need transportation to church? We can help.',
    media: ['Van-ministry.jpg'],
    content: `<div
  class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-860218e"
  data-id="860218e"
  data-element_type="column"
>
  <div class="elementor-widget-wrap elementor-element-populated">
    <div
      class="elementor-element elementor-element-e2e5396 elementor-widget elementor-widget-heading"
      data-id="e2e5396"
      data-element_type="widget"
      data-widget_type="heading.default"
    >
    <div
      class="elementor-element elementor-element-9c5c4bf elementor-widget elementor-widget-text-editor"
      data-id="9c5c4bf"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div class="elementor-widget-container">
        <style>
          /*! elementor - v3.14.0 - 18-06-2023 */
          .elementor-widget-text-editor.elementor-drop-cap-view-stacked
            .elementor-drop-cap {
            background-color: #69727d;
            color: #fff;
          }
          .elementor-widget-text-editor.elementor-drop-cap-view-framed
            .elementor-drop-cap {
            color: #69727d;
            border: 3px solid;
            background-color: transparent;
          }
          .elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
            .elementor-drop-cap {
            margin-top: 8px;
          }
          .elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
            .elementor-drop-cap-letter {
            width: 1em;
            height: 1em;
          }
          .elementor-widget-text-editor .elementor-drop-cap {
            float: left;
            text-align: center;
            line-height: 1;
            font-size: 50px;
          }
          .elementor-widget-text-editor .elementor-drop-cap-letter {
            display: inline-block;
          }
        </style>
        <p>
          The Van Ministry is vital in providing transportation to church
          services for those members who are in need, including Sunday school
          and the eleven o’clock worship service.&nbsp; Transportation is also
          offered for church-wide activities and related events.&nbsp;
        </p>
        <p>
          <strong
            >Some but not all policies governing van drivers and passengers
            include:</strong
          >
        </p>
        <ol>
          <li>
            Drivers must be at least 25 years, no more than 70 years old, and
            have five years of driving experience.
          </li>
          <li>
            Drivers must be appropriately licensed in the State of Florida for
            the size and type of vehicle that is being driven.
          </li>
          <li>
            Drivers are encouraged to carry cell phones for emergencies.
            However, drivers are not permitted to make calls or text while
            driving.&nbsp; Drivers are to pull off the road if using the phone
            becomes necessary in non-emergency situations.
          </li>
          <li>
            Drivers are to obey all posted speed limits and rules of the road.
          </li>
          <li>
            Drivers have the final responsibility and authority in matters
            relating to the safety of the van and its passengers.
          </li>
          <li>Drivers and all passengers are to wear seatbelts.</li>
        </ol>
        <p>
          All van passengers are expected to conduct themselves in a manner that
          exemplifies Christian principles.&nbsp; The church reserves the right
          to deny ridership to occupants whose conduct or health is detrimental
          to the general safety of the group.
        </p>
      </div>
    </div>
  </div>
</div>
 `,
  },
  {
    id: '27',
    name: 'Youth & Children’s Ministry',
    description: 'Grooming the future of our church and community.',
    media: ['youth.jpg'],
    content: `<div
  class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3f0ceab"
  data-id="3f0ceab"
  data-element_type="column"
>
  <div class="elementor-widget-wrap elementor-element-populated">
    <div
      class="elementor-element elementor-element-5d1d26c elementor-widget elementor-widget-heading"
      data-id="5d1d26c"
      data-element_type="widget"
      data-widget_type="heading.default"
    >
      <div class="elementor-widget-container">
        <h2 class="elementor-heading-title elementor-size-default">
          “But Jesus called the children to Him and said, Let the little
          children come to me, and do not hinder them, for the Kingdom of God
          belongs to such as these.”<br />
          LUKE 18-16 NIV
        </h2>
      </div>
    </div>
    <div
      class="elementor-element elementor-element-fda2834 list-type-styles elementor-widget elementor-widget-text-editor"
      data-id="fda2834"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div class="elementor-widget-container">
        <style>
          /*! elementor - v3.14.0 - 18-06-2023 */
          .elementor-widget-text-editor.elementor-drop-cap-view-stacked
            .elementor-drop-cap {
            background-color: #69727d;
            color: #fff;
          }
          .elementor-widget-text-editor.elementor-drop-cap-view-framed
            .elementor-drop-cap {
            color: #69727d;
            border: 3px solid;
            background-color: transparent;
          }
          .elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
            .elementor-drop-cap {
            margin-top: 8px;
          }
          .elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
            .elementor-drop-cap-letter {
            width: 1em;
            height: 1em;
          }
          .elementor-widget-text-editor .elementor-drop-cap {
            float: left;
            text-align: center;
            line-height: 1;
            font-size: 50px;
          }
          .elementor-widget-text-editor .elementor-drop-cap-letter {
            display: inline-block;
          }
        </style>
        <p>
          The New Mt. Zion Missionary Baptist Church’s Youth and Children’s
          &nbsp;Ministry fosters an environment where every child can develop a
          personal relationship with Jesus Christ and grow to love and serve Him
          through worship and teaching the Bible. The youth ministry ensures
          that spiritual growth happens in an atmosphere of physical and
          emotional safety.
        </p>
        <p>
          The ministry strives to create a supportive environment that is
          healthy, edifying, and loving. The church ensures that all youth
          volunteers act according to church policies and God’s higher standards
          of conduct. The youth ministry welcomes children from 4 years to 12
          years and teens from 12 years to 17 years of age. All youth ministry
          volunteers must complete an application and background check.
        </p>
        <p><strong>Some of the current youth activities include:</strong></p>
        <ul>
          <li>Vacation Bible School</li>
          <li>Back to School Bash</li>
          <li>Pizza parties with the Pastor</li>
          <li>Bible Drill Team</li>
          <li>Christian skate night</li>
          <li>Teen social nights</li>
          <li>Youth usher’s ministry</li>
          <li>Martin Luther King Observances</li>
        </ul>
      </div>
    </div>
  </div>
</div>
`,
  },
];
export const submitContactRequest = ({
  firstName,
  lastName,
  email,
  phone,
  note,
  selectedOption,
}: {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  note: string;
  selectedOption: string;
}) => {
  return addDoc(collection(db, 'forms'), {
    firstName,
    lastName,
    email,
    phone,
    note,
    selectedOption,
    formId: FormIds.contact,
  });
};

export const submitVolunteerForm = (data: VolunteerInput) => {
  return addDoc(collection(db, 'forms'), {
    ...data,
    formId: FormIds.volunteer,
  });
};
export const submitBudgetForm = (data: IBudgetFormData) => {
  return addDoc(collection(db, 'forms'), {
    ...data,
    formId: FormIds.budgetForm2025,
  });
};

export const submitBreastForm = (data: IBreastFormData) => {
  return addDoc(collection(db, 'forms'), {
    ...data,
    formId: FormIds.breastCancerSeminar2024,
  });
};

export const submitForm = (
  formId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, string | number | Date | any>
) => {
  //console.log({ formId, data });
  return addDoc(collection(db, 'forms'), {
    ...data,
    formId,
  });
};

// export const getEvents = async () => {
//   const events: ChurchEvent[] = [
//     // {
//     //   id: 'feed_the_homeless',
//     //   title: 'Feed The Homeless Project',
//     //   subTitle: '',
//     //   description: '',
//     //   startTime: new Date('09/09/2023 11:00 AM'),
//     //   endTime: new Date('09/09/2023 01:00 PM'),
//     //   media: ['homeless.png'],
//     //   creator: 'admin',
//     //   //signUpFormId: FormIds.breastCancerSeminar2024,
//     //   previewImage: 'homeless_prev.png',
//     // },
//     {
//       id: 'questions_jesus_asked',
//       title: 'Questions Jesus Asked',
//       subTitle: 'A Sermon Series by Pastor Edward L.Quary Jr',
//       description:
//         'A Sermon Series by Pastor Edward L.Quary Jr. Starting January 5th, 2025',
//       startTime: new Date('01/05/2025'),
//       endTime: new Date('03/01/2025'),
//       media: ['questions.png'],
//       creator: 'admin',
//       previewImage: 'questions.png',
//       link: '',
//     },
//     {
//       id: 'cpr_training',
//       title: 'CPR Training',
//       subTitle: '',
//       description: 'CPR Training',
//       startTime: new Date('02/05/2025'),
//       endTime: new Date('03/01/2025'),
//       media: ['cpr.png'],
//       creator: 'admin',
//       previewImage: 'cpr.png',
//       link: '',
//       externalLink: 'https://forms.gle/EZVGBpsfaaYagzcV8',
//     },
//     {
//       id: 'family-spring-festival-2024',
//       title: 'Family Spring Festival',
//       subTitle: '',
//       description: 'Family Spring Festival',
//       startTime: new Date('05/25/2024 10:00 AM'),
//       endTime: new Date('05/25/2024 02:00 PM'),
//       media: ['spring_2024.jpg'],
//       creator: 'admin',
//       previewImage: 'spring_2024.jpg',
//       link: '',
//     },
//     {
//       id: 'mental-health-2024',
//       title: 'Community Mental Health Symposium',
//       subTitle: '',
//       description: 'Sponsored by: NMZ Health and Wellness Ministry',
//       startTime: new Date('05/04/2024 08:30 AM'),
//       endTime: new Date('05/04/2024 11:30 AM'),
//       media: ['mental_2.png', 'mental_3.png'],
//       creator: 'admin',
//       signUpFormId: FormIds.mentalHealth2024,
//       previewImage: 'mental_0.png',
//       link: '',
//     },
//     {
//       id: 'cookout-2024',
//       title: 'Community Cookout',
//       subTitle: '',
//       description: '',
//       startTime: new Date('10/16/2024 04:30 PM'),
//       endTime: new Date('10/16/2024 07:30 PM'),
//       media: ['cookout.jpg'],
//       creator: 'admin',
//       //signUpFormId: FormIds.breastCancerSeminar2024,
//       previewImage: 'cookout.jpg',
//       link: '',
//     },
//     {
//       id: 'clothing-giveaway-2024',
//       title: 'Clothing Giveaway',
//       subTitle: '',
//       description: 'Sponsored by: NMZ Health and Wellness Ministry',
//       startTime: new Date('10/16/2024 04:30 PM'),
//       endTime: new Date('10/16/2024 07:30 PM'),
//       media: ['clothing.jpg'],
//       creator: 'admin',
//       //signUpFormId: FormIds.breastCancerSeminar2024,
//       previewImage: 'clothing.jpg',
//       link: '',
//     },
//     {
//       id: 'breast-cancer-2024',
//       title: 'Breast Health Awareness Community Seminar',
//       subTitle: '',
//       description: 'Sponsored by: NMZ Health and Wellness Ministry',
//       startTime: new Date('10/26/2024 07:30 AM'),
//       endTime: new Date('10/26/2024 11:30 AM'),
//       media: ['b-24-1.png', 'b-24-2.png'],
//       creator: 'admin',
//       signUpFormId: FormIds.breastCancerSeminar2024,
//       previewImage: 'b-24-1.png',
//       link: '',
//     },
//     //     {
//     //       id: 'wear_red_2024',
//     //       title: 'Wear Red Sunday',
//     //       subTitle: 'HEART HEALTH AWARENESS MONTH',
//     //       description:
//     //         'New Mt. Zion is going RED to raise awareness of heart disease',
//     //       startTime: new Date('02/18/2024 11:00 AM'),
//     //       endTime: new Date('02/18/2024 01:00 PM'),
//     //       media: ['heart_health_2024.jpeg'],
//     //       creator: 'admin',
//     //       //signUpFormId: FormIds.breastCancerSeminar2024,
//     //       previewImage: 'heart_health_2024.jpeg',
//     //     },
//     //     {
//     //       id: 'youth_gospel_explosion',
//     //       title: 'Youth Gospel Explosion',
//     //       subTitle: '',
//     //       description: 'Saturday December 16th, 5pm - 7pm',
//     //       startTime: new Date('12/16/2023 05:00 PM'),
//     //       endTime: new Date('12/16/2023 07:00 PM'),
//     //       media: [],
//     //       creator: 'admin',
//     //       //signUpFormId: FormIds.breastCancerSeminar2024,
//     //       previewImage: '',
//     //     },

//     //     {
//     //       id: 'breast_cancer_seminar_2023',
//     //       title: 'BREAST HEALTH AWARENESS COMMUNITY SEMINAR',
//     //       subTitle: '',
//     //       description: 'Sponsored by: NMZ Health and Wellness Ministry',
//     //       startTime: new Date('10/14/2023 09:00 AM'),
//     //       endTime: new Date('10/14/2023 12:00 PM'),
//     //       media: ['breast_cancer_1.png', 'breast_cancer_2.png'],
//     //       creator: 'admin',
//     //       signUpFormId: FormIds.breastCancerSeminar2024,
//     //       previewImage: 'breast_cancer_seminar_2023.png',
//     //     },
//     //     {
//     //       id: 'hallelujah_fest_2023',
//     //       title: 'Hallelujah Fest',
//     //       subTitle: '',
//     //       description: '',
//     //       startTime: new Date('10/31/2023 06:00 PM'),
//     //       endTime: new Date('10/31/2023 08:00 PM'),
//     //       media: ['hallelujah_fest_2023.jpg'],
//     //       creator: 'admin',
//     //       //signUpFormId: FormIds.breastCancerSeminar2024,
//     //       previewImage: 'hallelujah_fest_2023.jpg',
//     //     },
//     //     {
//     //       id: 'pastor_anniversary_2023',
//     //       title: "Pastor Quary's 3rd Anniversary",
//     //       subTitle: '',
//     //       description: '',
//     //       startTime: new Date('11/12/2023 11:00 AM'),
//     //       endTime: new Date('11/12/2023 12:30 PM'),
//     //       media: ['pastor_anniversary.jpg'],
//     //       creator: 'admin',
//     //       //signUpFormId: FormIds.breastCancerSeminar2024,
//     //       previewImage: 'pastor_anniversary.jpg',
//     //     },
//     //     {
//     //       id: 'sharathon_2023',
//     //       title: 'Shar-a-thon',
//     //       subTitle: "God's close - Free Shopping Day",
//     //       description: '',
//     //       startTime: new Date('11/18/2023 10:00 AM'),
//     //       endTime: new Date('11/18/2023 02:00 PM'),
//     //       media: ['sharathon.jpeg'],
//     //       creator: 'admin',
//     //       //signUpFormId: FormIds.breastCancerSeminar2024,
//     //       previewImage: 'sharathon2.png',
//     //     },
//     //     {
//     //       id: 'mlk_2024',
//     //       title: 'Dr. Martin L. King Dream MegaFEST, Jan 16, 2024',
//     //       subTitle: '',
//     //       description:
//     //         'Honoring the life and legacy of Dr. Martin Luther King,Jr. through a shared commitment to strengthen our community and improve lives.',
//     //       startTime: new Date('01/16/2024 06:30 PM'),
//     //       endTime: new Date('01/16/2024 07:30 PM'),
//     //       media: ['mlk_2024.jpeg'],
//     //       creator: 'admin',
//     //       //signUpFormId: FormIds.breastCancerSeminar2024,
//     //       previewImage: 'mlk_2024.jpeg',
//     //     },
//     //     {
//     //       id: 'wellness_2024',
//     //       title: '1st Annual Wellness Wednesday',
//     //       subTitle: '',
//     //       description: `- Free Immunizations (Medicare Part D Only)
//     // - Medicare Eligibility
//     // - Healthy Eating Tips
//     // - Doc Talks
//     // -"Ask a Pharmacist"
//     // - Blood Pressure Checks
//     // - Estate Planning
//     // - Exercise & Strengthening
//     // - Consultation Booths
//     // - Door Prize Giveaways`,
//     //       startTime: new Date('01/17/2024 09:00 AM'),
//     //       endTime: new Date('01/17/2024 02:00 PM'),
//     //       media: ['wellness_2024.png'],
//     //       creator: 'admin',
//     //       //signUpFormId: FormIds.breastCancerSeminar2024,
//     //       previewImage: 'wellness_2024.png',
//     //     },
//     // {
//     //   id: 'youth-christmas-2024',
//     //   title: "Youth and Children's Christmas Celebration",
//     //   subTitle: '',
//     //   description: 'Register by 12/15/2024',
//     //   startTime: new Date('12/20/2024 06:00 PM'),
//     //   endTime: new Date('12/20/2024 09:00 PM'),
//     //   media: ['youth-christmas-2024.png'],
//     //   creator: 'admin',
//     //   signUpFormId: FormIds.youthChristmasPart2024,
//     //   previewImage: 'youth-christmas-2024.png',
//     //   link: '',
//     // },
//     {
//       id: 'mortgage_burning',
//       title: 'Mortgage Burning Ceremony',
//       subTitle: '',
//       description: '',
//       startTime: new Date('08/11/2024 11:00 AM'),
//       endTime: new Date('08/11/2024 01:00 PM'),
//       media: ['burn_mortgage.png'],
//       creator: 'admin',
//       previewImage: 'burn_mortgage.png',
//       link: '',
//     },
//     // {
//     //   id: '2025_budget',
//     //   title: 'Mortgage Burning Ceremony',
//     //   subTitle: '',
//     //   description: '',
//     //   startTime: new Date('08/23/2024'),
//     //   endTime: new Date('09/26/2024'),
//     //   media: ['burn_mortgage.png'],
//     //   creator: 'admin',
//     //   previewImage: 'burn_mortgage.png',
//     //   link: '/forms/budget-request-form-2025',
//     //   signUpFormId: FormIds.budgetForm2025,
//     // },
//   ];

//   return Promise.resolve(events);
// };

export const useEvents = (id = '') => {
  const [busy, setBusy] = useState(false);
  const [events, setEvents] = useState<ChurchEvent[]>([]);
  useEffect(() => {
    setEvents([]);
    const q = !id
      ? query(collection(db, 'events'), orderBy('createdOn', 'desc'), limit(50))
      : query(collection(db, 'events'), where('id', '==', id), limit(50));
    //04/24/2024
    setBusy(true);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setBusy(false);
      const z: ChurchEvent[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        z.push({
          ...data,
          ...{
            id: doc.id,
            startTime: data.startTime.toDate(),
            endTime: data.endTime.toDate(),
          },
        } as ChurchEvent);
      });

      setEvents(z);
    });
    return () => {
      unsubscribe();
    };
  }, [id]);

  const createEvent = async (data: ChurchEvent) => {
    const storage = getStorage();
    const mediaUrls = await Promise.all(
      data.media.map(async (media) => {
        if (media.startsWith('data:image')) {
          const storageRef = ref(
            storage,
            `events/${Date.now()}-${Math.random().toString(36).substring(7)}`
          );
          await uploadString(storageRef, media, 'data_url');
          return await getDownloadURL(storageRef);
        }
        return media;
      })
    );

    const nref = doc(collection(db, 'events'));
    return setDoc(nref, {
      ...data,
      media: mediaUrls,
      createdOn: new Date(),
    });
  };

  const deleteEvent = (id: string) => {
    return deleteDoc(doc(collection(db, 'events'), id));
  };

  const updateEvent = (id: string, data: Partial<ChurchEvent>) => {
    return updateDoc(doc(collection(db, 'events'), id), data);
  };
  return { busy, events, createEvent, deleteEvent, updateEvent };
};

export interface Bulletin {
  id: string;
  link: string;
  month: string;
  year: string;
  createdOn: Date;
}
export const useBulletins = (id = '') => {
  const [busy, setBusy] = useState(false);
  const [bulletins, setBulletins] = useState<Bulletin[]>([]);
  useEffect(() => {
    setBulletins([]);
    const q = !id
      ? query(
          collection(db, 'bulletins'),
          orderBy('createdOn', 'desc'),
          limit(50)
        )
      : query(collection(db, 'bulletins'), where('id', '==', id), limit(50));
    //04/24/2024
    setBusy(true);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setBusy(false);
      const z: Bulletin[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        z.push({
          ...data,
          ...{
            id: doc.id,
            createdOn: data.createdOn.toDate(),
          },
        } as Bulletin);
      });

      setBulletins(z);
    });
    return () => {
      unsubscribe();
    };
  }, [id]);

  const createBulletin = async ({
    month,
    year,
    file,
  }: {
    month: string;
    year: string;
    file: File | null;
  }) => {
    const data = {
      id: `${month}-${year}`,
      month,
      year,
      link: '',
      createdOn: new Date(),
    };
    const storage = getStorage();

    if (file !== null) {
      const storageRef = ref(
        storage,
        `bulletins/${Date.now()}-${Math.random().toString(36).substring(7)}`
      );
      await uploadBytesResumable(storageRef, file);
      data.link = await getDownloadURL(storageRef);
    }
    const nref = doc(collection(db, 'bulletins'), data.id);
    return setDoc(nref, data);
  };

  const deleteBulletin = (id: string) => {
    return deleteDoc(doc(collection(db, 'bulletins'), id));
  };

  return { busy, bulletins, createBulletin, deleteBulletin };
};

export const createNewAnnouncement = (data: Announcement) => {
  const nref = doc(collection(db, 'announcements'));
  return setDoc(nref, {
    ...data,
    id: nref.id,
  });
};

export const deleteAnnouncement = (id: string) => {
  return deleteDoc(doc(collection(db, 'announcements'), id));
};
export const useAnnouncements = (all = false) => {
  const [busy, setBusy] = useState(false);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  useEffect(() => {
    setAnnouncements([]);
    const q = all
      ? query(
          collection(db, 'announcements'),
          orderBy('createdOn', 'desc'),
          limit(50)
        )
      : query(
          collection(db, 'announcements'),
          where('displayUntil', '>=', new Date()),
          where('enabled', '==', true),
          limit(50)
        );
    //04/24/2024
    setBusy(true);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setBusy(false);
      const z: Announcement[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        z.push({
          ...data,
          ...{
            id: doc.id,
            createdOn: data.createdOn.toDate(),
            displayUntil: data.displayUntil.toDate(),
          },
        } as Announcement);
      });

      setAnnouncements(z);
    });
    return () => {
      unsubscribe();
    };
  }, [all]);

  return { busy, announcements };
};

export const getCompletedForm = async (id: string) => {
  const docRef = doc(db, 'forms', id);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};

export const shortenlUrl = async (link: string) => {
  const longUrl = encodeURIComponent(link);
  const url = `https://tinyurl.com/api-create.php?url=${longUrl}`;

  fetch(url)
    .then((response) => response.text())
    .then((data) => console.log(data))
    .catch((error) => console.error('Error:', error));
};
