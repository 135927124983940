import { Box } from '@mui/system';
import React from 'react';
import { ChurchEvent } from '../../services';
import { Button, Card } from '@mui/material';
import { Event } from '@mui/icons-material';
import { colors } from '../../utils';
import { useNavigate } from 'react-router-dom';

const dateFormat: Intl.DateTimeFormatOptions = {
  dateStyle: 'medium',
};
const EventDetails = ({ event }: { event: ChurchEvent }) => {
  const nav = useNavigate();
  return (
    <Card
      sx={{ margin: '1rem', padding: '1rem', width: { md: '50%', xs: '100%' } }}
    >
      <Box>
        <Box>
          <Box
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '1rem', md: '1.5rem', padding: '0rem 1rem' },
            }}
          >
            {event.title}
          </Box>
        </Box>
        <Box
          sx={{
            fontSize: { xs: '0.8rem', md: '1rem', padding: '0.5rem 1rem' },
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Event sx={{ color: colors.themeRed, marginRight: '0.25rem' }} />
          <div>
            {event.startTime.toLocaleDateString('en-US', dateFormat)},{' '}
            {event.startTime.toLocaleTimeString('en-US', {
              timeStyle: 'short',
            })}{' '}
            {event.endTime && (
              <>
                - {event.endTime.toLocaleDateString('en-US', dateFormat)}{' '}
                {event.endTime.toLocaleTimeString('en-US', {
                  timeStyle: 'short',
                })}
              </>
            )}
          </div>
          {/* <div>{event.startTime.toLocaleString()}</div> */}
        </Box>
        <Box
          sx={{
            fontSize: { xs: '1rem', md: '1rem', padding: '0.5rem 1rem' },
          }}
        >
          {event.description}
        </Box>

        {event.signUpFormId && (
          <Button
            variant="contained"
            sx={{ ml: 2 }}
            onClick={() => nav(`/forms/${event.signUpFormId}`)}
          >
            Register
          </Button>
        )}
        {event.link && (
          <Button
            variant="contained"
            sx={{ ml: 2 }}
            onClick={() => {
              window.open(event.link, '_blank');
            }}
          >
            Register
          </Button>
        )}
        <Box
          sx={{
            fontSize: { xs: '1rem', md: '1rem', padding: '0.5rem 1rem' },
          }}
        >
          {event.media.map((m) => (
            <img
              src={m.startsWith('http') ? m : require(`../../images/${m}`)}
              alt={event.title}
              key={m}
              style={{
                width: '100%',
                margin: '0.5rem',
                border: '1px solid gray',
              }}
            />
          ))}
        </Box>
      </Box>
    </Card>
  );
};

export default EventDetails;
