// src/components/About.tsx
import React from 'react';
import CashAppLogo from '../../images/DonateThroughCashApp.png';

import Vanco from '../../images/vancologo-640w.webp';

import {
  Box,
  Button,
  Card,
  CardContent,
  colors as muiColors,
} from '@mui/material';
import { colors } from '../../utils';
import './index.css';
import { Done, LightbulbOutlined, Mail, Message } from '@mui/icons-material';
const cashAppSteps = [
  'Download Cash App',
  'Open the app',
  'Tap “Pay”',
  'In the “To:” field type $NMZLAKELAND',
  'In the “For:” field type your name, phone number, and the giving fund: Tithes, Donation, Offering, Missions, etc.',
  'Tap “Pay”',
  'Confirm your cash “PIN”',
];

const vancoSteps = [
  'Click the button below',
  'Select what you would like to donate or contribute to: Tithes, Offerings, Sunday School, Church Anniversary, Community outreach & Engagement, Mission & Education or 40 for 40 Mortgage Payoff',
  'Type in donation $ amount',
  'Type in the frequency of the donation',
  'Select a start date for your contribution',
  'Tap the purple “Contribute" or "Donate" button',
];
const Donate = () => {
  return (
    <div className="contactus">
      <div className="contactus-hero">Give</div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          //flexFlow: 'wrap',
          justifyContent: 'center',
          margin: '2rem',
        }}
      >
        <Box
          sx={{
            color: colors.themeRed,
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '1.3rem',
            margin: '1rem',
          }}
        >
          Acts 20:35
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            // fontWeight: 'bold',
            fontSize: {
              md: '1.5rem',
              xs: '1.0rem',
            },
          }}
        >
          “In everything I did, I showed you that by this kind of hard work we
          must help the weak, remembering the words the Lord Jesus himself said:
          ‘It is more blessed to give than to receive”{' '}
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: {
              md: '2rem',
              xs: '1.5rem',
            },
            textDecoration: 'underline',
            textDecorationColor: colors.themeRed,
            textDecorationThickness: '0.2rem',
            margin: '2rem',
            textUnderlineOffset: '1rem',
          }}
        >
          SIMPLE WAYS TO GIVE
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <Card
            sx={{
              padding: 2,
              margin: 1,
              maxWidth: {
                md: '500px',
                xs: '400px',
              },
              backgroundColor: muiColors.grey.A100,
            }}
          >
            <CardContent className="contactus-card">
              <a
                href="https://secure.myvanco.com/YP2B/campaign/C-Z3M5"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Vanco} alt="Logo" height={64} />
              </a>
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.3rem',
                  margin: '1rem',
                }}
              >
                VANCO
              </div>
              <div>
                {vancoSteps.map((x) => (
                  <div
                    key={x}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Done sx={{ color: colors.themeRed, margin: '0.25rem' }} />{' '}
                    {/* {x} */}
                    <div style={{ textAlign: 'initial' }}>{x}</div>
                  </div>
                ))}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }}
                >
                  <LightbulbOutlined
                    sx={{ color: colors.themeRed, margin: '0.25rem' }}
                  />{' '}
                  {/* {x} */}
                  <div style={{ textAlign: 'initial' }}>
                    Make donating easier by setting up recurring payments
                  </div>
                </div>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: {
                      md: 'row',
                      xs: 'column',
                    },
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      color: colors.themeRed,
                      borderColor: colors.themeRed,
                      marginTop: '1rem',
                    }}
                    href="https://secure.myvanco.com/YP2B/campaign/C-Z3M5"
                    target="_blank"
                    // startIcon={<img src={Vanco} height={24} />}
                  >
                    GIVE Now
                  </Button>
                </Box>
              </div>
            </CardContent>
          </Card>
          <Card
            sx={{
              padding: 2,
              margin: 1,
              maxWidth: {
                md: '500px',
                xs: '400px',
              },
              backgroundColor: muiColors.grey.A100,
            }}
          >
            <CardContent className="contactus-card">
              <a
                href="https://cash.app/$NMZLakeland"
                target="_blank"
                rel="noreferrer"
              >
                <img src={CashAppLogo} alt="Logo" height={64} />
              </a>
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.3rem',
                  margin: '1rem',
                }}
              >
                CASH APP
              </div>
              <div>
                {cashAppSteps.map((x) => (
                  <div
                    key={x}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Done sx={{ color: colors.themeRed, margin: '0.25rem' }} />{' '}
                    {/* {x} */}
                    <div style={{ textAlign: 'initial' }}>{x}</div>
                  </div>
                ))}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: {
                      md: 'row',
                      xs: 'column',
                    },
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      color: colors.themeRed,
                      borderColor: colors.themeRed,
                      marginTop: '1rem',
                    }}
                    href="https://cash.app/$NMZLakeland"
                    target="_blank"
                  >
                    GIVE Now
                  </Button>
                </Box>
              </div>
            </CardContent>
          </Card>

          <Card
            sx={{
              padding: 2,
              margin: 1,
              maxWidth: {
                md: '500px',
                xs: '400px',
              },
              backgroundColor: muiColors.grey.A100,
            }}
          >
            <CardContent className="contactus-card">
              <Message sx={{ fontSize: '4rem' }} />
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.3rem',
                  margin: '0.5rem',
                }}
              >
                Text Message
              </div>
              <div>
                Send using Vanco by Texting: <b>(844) 699-3296</b>
              </div>
              <div>Text $ amount, Follow texted link</div>
            </CardContent>
          </Card>
          <Card
            sx={{
              padding: 2,
              margin: 1,
              maxWidth: {
                md: '500px',
                xs: '400px',
              },
              backgroundColor: muiColors.grey.A100,
            }}
          >
            <CardContent className="contactus-card">
              <Mail sx={{ fontSize: '4rem' }} />
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.3rem',
                  margin: '0.5rem',
                }}
              >
                Mail or Drop-Off
              </div>
              <div>1321 N. Webster Avenue Lakeland, FL 33805</div>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </div>
  );
};

export default Donate;
