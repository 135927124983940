export const colors = {
  themeRed: '#BF0A30',
};
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const sundaySchoolTeachers = ['natbcc97@gmail.com'];
