// src/components/About.tsx

import Button from '@mui/material/Button';
import React from 'react';

const Sermons = () => {
  const isWithinTimeRange = () => {
    const now = new Date();
    const day = now.getDay(); // 0 (Sunday) to 6 (Saturday)
    const hours = now.getHours();

    if (day >= 0 && day <= 3) {
      // Sunday to Tuesday
      if (day === 0 && hours < 14) {
        // Sunday before 2pm
        return false;
      }
      if (day === 3 && hours >= 19) {
        // Wednesday after 7pm
        return false;
      }
      return true;
    }

    return false;
  };

  const index = isWithinTimeRange() ? 1 : 2;

  return (
    <div className="sermons">
      <div>Recent Video</div>
      <div className="iframe-container">
        <iframe
          src={`https://www.youtube.com/embed?listType=playlist&list=UU2OOtv9Mutzsd6pH5vu-BIA&index=${
            index - 1
          }`}
        ></iframe>
      </div>
      {/* <Box>
          <iframe
            //style={{ width: '200%' }}
            src={`https://www.youtube.com/embed?listType=playlist&list=UU2OOtv9Mutzsd6pH5vu-BIA&index=${index}`}
          />
        </Box> */}
      <Button
        variant="contained"
        href="https://www.youtube.com/@nmzlakeland/streams"
        target="_blank"
        color="primary"
        style={{ backgroundColor: 'rgb(233, 32, 79)' }}
      >
        View Past Sermons
      </Button>
    </div>
  );
};

export default Sermons;
